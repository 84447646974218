<template>
  <div>
    <b-card title="Kick start your project 🚀">
     
      
      <b-button @click="createData">Create </b-button>
    </b-card>
  </div>
</template>
  
  <script>
import { BCard, BCardText, BLink, BButton } from "bootstrap-vue";
import API from "@/views/API/condb.js";
import axios from "axios";
import _ from "lodash";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
  },
  data() {
    return {};
  },
  async created() {
    if (
      _.isEmpty(localStorage.getItem("access_token")) ||
      _.isEmpty(localStorage.getItem("access_user"))
    ) {
      localStorage.removeItem("access_user");
      localStorage.removeItem("access_token");
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    async createData() {
      const { userId } = await JSON.parse(localStorage.getItem("access_user"));
      const mid = userId;
      const uid = this.$route.params.id;
      // console.log(mid);
      // console.log(uid);
      const data = new FormData();
      data.append("dateOfBirth", "2022-30-11");
      data.append("dipChipDateTime", "2022-12-30 12:55:66");
      data.append("mId", mid);
      data.append("uId", uid);
      const url = `${API}smartData`;
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };

      const res = await axios.post(url, data, head);
      console.log(res);
    },
    async access_token() {
      return (
        await axios.post(`${API}token`, "", {
          headers: {
            Authorization:
              localStorage.getItem("access_token") &&
              JSON.parse(localStorage.getItem("access_token")),
          },
        })
      ).data.message;
    },
  },
};
</script>
  
  <style>
</style>
  